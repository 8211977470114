<template>
  <div>
    <h1 class="text-white">PRIVACY POLICY</h1>

    <em class="text-white">Last updated: April 21, 2023</em>

    <p class="text-white">
      MeetLouis operated by StopLeads OÜ ("StopLeads," "we," "us," "our") respects your privacy and is committed to protecting
      your personal data. This Privacy Policy explains how we collect, use, store, and protect your personal data when you use the
      MeetLouis platform and services (the "Services").
    </p>

    <h2 class="text-white">Data We Collect</h2>
    <p class="text-white">
      1.1 Information You Provide: We collect personal data that you provide to us when you create an account, use the Services,
      or communicate with us. This may include your name, email address, phone number, payment information, and other information
      that you provide to us.
    </p>

    <p class="text-white">
      1.2 Usage Information: We may collect information about how you use the Services, including your IP address, browser type,
      device type, operating system, and other usage data.
    </p>

    <p class="text-white">
      1.3 Cookies and Other Tracking Technologies: We use cookies and other tracking technologies to collect information about
      your use of the Services, including your preferences and browsing history.
    </p>

    <h2 class="text-white">Use of Your Data</h2>
    <p class="text-white">
      2.1 Providing and Improving the Services: We use your personal data to provide and improve the Services, including to
      personalize your experience, process payments, and communicate with you.
    </p>

    <p class="text-white">
      2.2 Marketing: We may use your personal data to send you marketing communications about our Services or third-party products
      and services that we think may be of interest to you.
    </p>

    <p class="text-white">
      2.3 Legal and Compliance: We may use your personal data to comply with applicable laws and regulations, to respond to legal
      requests, or to protect our rights, property, or safety.
    </p>

    <h2 class="text-white">Sharing Your Data</h2>

    <p class="text-white">
      3.1 Service Providers: We may share your personal data with service providers who help us provide the Services, such as
      payment processors, hosting providers, and customer support providers.
    </p>

    <p class="text-white">
      3.2 Legal and Compliance: We may share your personal data to comply with applicable laws and regulations, to respond to
      legal requests, or to protect our rights, property, or safety.
    </p>

    <p class="text-white">
      3.3 Business Transfers: We may share your personal data as part of a merger, acquisition, or sale of assets, or in the event
      of bankruptcy.
    </p>

    <h2 class="text-white">Data Security</h2>

    <p class="text-white">
      4. We use appropriate technical and organizational measures to protect your personal data against unauthorized access,
      alteration, disclosure, or destruction. However, no security system is perfect, and we cannot guarantee the security of your
      data.
    </p>

    <h2 class="text-white">Your Rights</h2>

    <p class="text-white">
      5. You have certain rights with respect to your personal data, including the right to access, correct, and delete your data.
      You may also have the right to object to or restrict certain processing of your data, or to request that we transfer your
      data to another controller. To exercise your rights, please contact us using the contact information below.
    </p>

    <h2 class="text-white">Children's Privacy</h2>

    <p class="text-white">
      6. The Services are not directed to children under the age of 18, and we do not knowingly collect personal data from
      children under the age of 18. If you are under the age of 18, please do not use the Services or provide us with any personal
      data.
    </p>

    <h2 class="text-white">Changes to this Privacy Policy</h2>

    <p class="text-white">
      7. We may update this Privacy Policy from time to time. If we make material changes to this Privacy Policy, we will notify
      you by email or by posting a notice on our website. Your continued use of the Services after the effective date of any
      changes to this Privacy Policy constitutes your acceptance of those changes.
    </p>

    <h2 class="text-white">Contact Us</h2>

    <p class="text-white">
      8. If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at
      hello@meetlouis.ai
    </p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style lang="scss" scoped>
.legal {
  h1,
  h2,
  p {
    color: white;
  }
}
</style>
